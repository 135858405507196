import { faHome,faThList,faCog, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import { PartnerdatamanagementService,PreceptorService } from '../shared/index';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
//import {FormControl,ReactiveFormsModule} from '@angular/forms';

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService } from '../msal';
import { MSAL_GUARD_CONFIG } from '../msal/constants';
import { MsalGuardConfiguration } from '../msal/msal.guard.config';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { EventMessage, EventType, InteractionType,  InteractionStatus, AuthenticationResult } from '@azure/msal-browser';
import { AuthenticationService } from '../shared/authentication.service';
import { commonService } from '../shared/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  faHome = faHome;
  faThList = faThList;
  faCog = faCog;

  loggedIn=false;
  userName:String='';
  userTemplate:any='userInfo.html';
  universities:any=[];
  popoverTrigger=false;
  university = {};
  PartnerGUID='';
  constructor(private authService: MsalService,private _auth:AuthenticationService,private _data:commonService) { }
  
  ngOnInit(): void {
    var userName;
    setTimeout(() => {
      let globals:any = localStorage.getItem('userDetail');
      this.userName = JSON.parse(globals).UserName;
    }, 300);
  }
  
  
  logout() {
    this.authService.logout();
  }
 
}
