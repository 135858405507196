
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable , ErrorObserver } from 'rxjs';
import {AuthenticationService } from "./authentication.service";
import {Router} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { map, catchError } from 'rxjs/operators';

import { throwError } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable()
export class ProxyService {
    data: any;
    auditconfig: any;
    private currentRequests: number = 0;
    constructor(private http: HttpClient, private _auth: AuthenticationService, private _router: Router,private toastr:ToastrService) {
    }
    postData(url : string, data : any) {
        this.incrementRequestCount()
        let httpOptions = {
            'Content-Type': 'application/json'
        };
       return this.http.post(url, data, { headers: httpOptions }).pipe(
        map(response => response),
        catchError((e: any)=>{
            return this.handleError(e)
        })
      );
    }

    postErrorData(url:string, data:any) {
        this.incrementRequestCount()
        let httpOptions = {
            'Content-Type': 'application/json'
        };
        let dataSend = data;
        return this.http.post(url, dataSend, { headers: httpOptions }).pipe(
            map(response => response),
            catchError((e: any)=>{
                return this.handleError(e)
            })
          );
    }

    getData(url:string) {
        this.incrementRequestCount()
        let httpOptions = {
            'Content-Type': 'application/json'
        };
        return this.http.get(url, { headers: httpOptions }).pipe(
            map(response => response),
            catchError((e: any)=>{
                return this.handleError(e)
            })
          );
    }

    handleError(error:any) {
        this.toastr.error(error.message, error.statusText, {closeButton:true});
        return throwError(error);
    };
   
    decrementRequestCount() {
        if (--this.currentRequests == 0) {
            this._auth.checkLoading(false);
        }
    }

    incrementRequestCount() {
        if (this.currentRequests++ == 0) {
            this._auth.checkLoading(true);
        }
    }  
}
