import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';

@Pipe({
  name: 'tel'
})
export class TelPipe implements PipeTransform {

    transform(phoneValue: number | string, country: string='US'): any {
        try {
            if(phoneValue==0 || phoneValue=='' || phoneValue==null) return '';
          const phoneNumber = parsePhoneNumber(phoneValue + '', country as CountryCode);
          return phoneNumber.formatNational();
        } catch (error) {
          return phoneValue;
        }
    }

}
