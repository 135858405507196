import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { EventMessage, EventType } from '@azure/msal-browser';
import { filter, repeat } from 'rxjs/operators';
import { isDefined } from '@angular/compiler/src/util';
import { Subject } from 'rxjs';
import { PartnerdatamanagementService,PreceptorService,ProxyService } from 'src/app/shared/index';
import {ServiceUrl} from '../utils/serviceurl.constants';


@Component({
    selector: 'app-preceptor-list',
    templateUrl: './preceptors-list.component.html',
    styleUrls: ['./preceptors-list.component.scss'],
})

export class PreceptorsListComponent implements OnDestroy, OnInit{
    @ViewChild(DataTableDirective)
    dtElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();
    searchval!:string;
  
    preceptorsList:any=[];
    univArray:any=[];
    partnerList:any=[];
    check:any=[];
    repeatArray:any=[]
    sectionKeys:any = [];
    filteredpreceptorsList:any=[];
    loginDisplay = false;
  

    constructor(private preceptorService: PreceptorService,private router: Router,private _proxyService: ProxyService   ) { }

    ngOnInit(): void {
        $.fn['dataTable'].ext.search.push((settings: any, data: any, dataIndex: any) => {
            let isExists=false;
            if(this.repeatArray.length==0) isExists=true;
            this.repeatArray.filter( (a:any )=> { if(a.name === data[1]) isExists=true; });
            if((this.searchval===undefined || this.searchval=='') && isExists ) return true;
            if(isExists && this.searchval && (data.filter((a:string) => a.toLocaleLowerCase().includes(this.searchval.toLowerCase() ))).length>0) return true; else return false;
        });
        this.getAllPreceptors();
        this.getAllPartners();
        this.dtOptions = {
            pagingType:'simple_numbers'
            ,dom:'rtpl' // r-processing , f- filter(search), t-table, p-paging , l-length
            ,pageLength: 10
            ,processing: true
            ,lengthMenu : [5, 10, 20,25,100]
            ,"info"    : false
            ,"order": [[ 1,'asc']]
            ,columnDefs: [ { type: 'date', 'targets': [1] } ]
        };
    };
    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
        $.fn['dataTable'].ext.search.pop();
    }
    reRender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {dtInstance.draw();});
    };
    filterByPartnerCodeAndStatus(row:any):boolean {
        let predfinedPartnerList= ['UTA', 'NKU', 'STU', 'LASALLE', 'La Salle', 'SUNY', 'Houston', 'UH', 'WPUNJ', 'YSU', 'ASU'];
        let BusinessContractStatusTypeNames= ['Active', 'Anticipating Separation'];
        return ((predfinedPartnerList.findIndex(x => x === row.name) !== -1) && BusinessContractStatusTypeNames.findIndex(x=>x===row.status) !== -1);
    };
  
    pushingToFilters(obj:any):void {
        if (obj.sectionKey == "University") this.univArray.push(obj.name);
    };

    splicingFilters(obj:any):void {
		if (obj.sectionKey == "University") this.univArray.splice(this.univArray.indexOf(obj.name), 1);
	};

    checkexists(InternalUniversityCode:string):boolean {
        if(this.repeatArray.length>0) {
        let a= ((this.repeatArray.findIndex((x:any) =>( x.name === InternalUniversityCode)) !== -1));
        return a;
        } else return true;
    };

    getSectionKeys():void {
		let sectionKeys:any = [];
		this.repeatArray.map(function (objArr:any) {
			if (sectionKeys.indexOf(objArr.sectionKey) == -1)
				sectionKeys.push({ 'id': objArr.id, 'sectionKey': objArr.sectionKey });
		});
        this.sectionKeys=sectionKeys;
        this.reRender();
	};
    onCheckBoxClick(obj:any):void{
        if (this.repeatArray.indexOf(obj) == -1) {
			this.repeatArray.push(obj);
			this.pushingToFilters(obj);
		} else {
			this.repeatArray.splice(this.repeatArray.indexOf(obj), 1);
			this.splicingFilters(obj);
		}
		this.getSectionKeys();
    };

    onLinkClick(i:any):void {
        this.check[0].checked = !this.check[0].checked;
    };

    removeBox(obj:any):void {
		obj.mode = false;
		this.check.map(function (objCheck:any) {
			if (objCheck.id == obj.id)
				objCheck.mode = false;
		});
		this.repeatArray.splice(this.repeatArray.indexOf(obj), 1);
		this.splicingFilters(obj);
		this.getSectionKeys();
	};

    ClearFilters(flg:any):void {
        //unchecking all the checkboxes checked earlier
        this.check.map(function (val:any) {
			val.checkBoxes.map(function (obj:any) {
				obj.mode = false;
			});
		});
		this.repeatArray = [];
		this.univArray = [];
		if (flg) this.sectionKeys = [];
        this.reRender();
    };

    precDetail(PreceptorId:any):void {this.router.navigate(['/preceptordetails', PreceptorId]);}

    getAllPartners():void {
        this._proxyService.postData(ServiceUrl.getPartnersList,{}).subscribe((resp: any) => {
            this.partnerList = resp.ApiResponse;
		    let UniversitycheckBoxes:any = [];
            this.partnerList.forEach((item:any, index:number) => {
                UniversitycheckBoxes.push({
                    id: item.PartnerID,
                    mode: false,
                    name: item.InternalUniversityCode,
                    value: item.InternalUniversityCode,
                    status: item.BusinessContractStatusTypeName,
                    sectionKey: "University",
                    filterKey: "UniversityFullName"
                });
            });
            this.check[0] = {
                section: "University",
                key: "University",
                checked: false,
                checkBoxes: UniversitycheckBoxes
            };
            this._proxyService.decrementRequestCount();
        },error=>{console.log('GetPartnerList:'+error); this._proxyService.decrementRequestCount();});
    };

    getAllPreceptors():void {
        const data= {entity:'PreceptorDetails'} ;
        this._proxyService.postData(ServiceUrl.getPreceptorsList,data).subscribe((resp: any) => {
            this.preceptorsList=resp.Response;
            this.filteredpreceptorsList=resp.Response;
            this.dtTrigger.next();
            this._proxyService.decrementRequestCount();
        },error=>{console.log('Preceptorlisterror:'+error);this._proxyService.decrementRequestCount();});
    };
}
