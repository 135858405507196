import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { ToastrModule } from 'ngx-toastr';
import { DatepickerModule } from './components/datepicker.module';



import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PreceptorsListComponent } from './preceptors-list/preceptors-list.component';
import { PreceptorDetailsComponent } from './preceptor-details/preceptor-details.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MsalService, MSAL_INSTANCE, MsalGuard, MsalInterceptor, MsalBroadcastService } from './msal';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './msal/constants';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { MsalInterceptorConfig } from './msal/msal.interceptor.config';

import { AuthenticationService,ProxyService, commonService}  from './shared/index';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderModule } from 'ngx-order-pipe';
import { DataTablesModule } from "angular-datatables";
import { FilterPipe } from './pipes/filter.pipe';
import { TelPipe } from './pipes/tel.pipe';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { PreceptorFormComponent } from './preceptor-form/preceptor-form.component';
import { FilterByPartnerCodeAndStatusPipe } from './pipes/filter-by-partner-code-and-status.pipe';
import { environment } from 'src/environments/environment';
function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
        clientId: environment.adClientId,
        authority: environment.adAauthority,
        redirectUri: environment.adRedirectUrl,
        postLogoutRedirectUri: environment.adPostLogoutRedirectUri

    }
  });
}
function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.preceptorServiceURL, environment.apiConfig.scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };

}
@NgModule({
  declarations: [
    AppComponent,
    PreceptorsListComponent,
    PreceptorDetailsComponent,
    PreceptorFormComponent,
    HeaderComponent,
    FooterComponent,
    FilterPipe,
    TelPipe,
    ConfirmationDialogComponent,
    PreceptorFormComponent,
    FilterByPartnerCodeAndStatusPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    AppRoutingModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    HttpClientModule,
    FontAwesomeModule,
    NgbModule,
    OrderModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    DatepickerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: {
        interactionType: InteractionType.Redirect
      } as MsalGuardConfiguration
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthenticationService,
    commonService,
    ProxyService,
    ConfirmationDialogService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }