<header>
  <div class="header-row wrapper container-fluid nopaddingLeft">
    <div class="row nomargin">
      <div class="header_logo header_logoFull col-3 width20 nopadding removeLogo">
        <a>
          <img src="/Images/RISEPOINT_LOGO_GREEN_RGB.png" alt="logo" height="80" />
        </a>
      </div>
      <div class="header_menu nopadding col-9 w-80">
        <div class="row">
          <div class="col-7">

          </div>
          <div class="col-3 col-xs-offset-3 iconsRight">


          </div>
          <div class="col-2" id="logout">
            <div class="row ">
              <div class="col welcomeText">
                Welcome
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button class="solutionName userSelectBox" placement="bottom" [ngbPopover]="popContent">
                  {{userName}}
                </button>
                <ng-template class="logout" #popContent>
                  <div (click)="logout()">Logout</div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <ng-template #solutionList>
      <div class="dropdownText" ngclass="selectedSolution.id == solution.id ? 'hideDisplay' : ''" ng-repeat="solution in solutionList" ng-click="changeSolution(solution)" id="solution.idName" ng-if="solution.perm">
          {{solution.name}}
          <img src="{{solution.imageSrc}}" alt="icon" />
      </div>
    </ng-template> -->
</header>