import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PreceptorService,ProxyService } from '../shared';
import { faLongArrowAltLeft,faCalendarAlt,faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ServiceUrl } from '../utils/serviceurl.constants';


@Component({
  selector: 'app-preceptor-details',
  templateUrl: './preceptor-details.component.html',
  styleUrls: ['./preceptor-details.component.scss']
})
export class PreceptorDetailsComponent implements OnInit {

    preceptorId: string;
    
    faArrowLeft=faLongArrowAltLeft;
    preceptorDetails: any;

    constructor(private actRoute: ActivatedRoute,private router:Router ,
        private confirmationDialogService :ConfirmationDialogService, 
        private preceptorService: PreceptorService,
        private _proxyService:ProxyService,
        private toastr: ToastrService
    ) {
        this.preceptorId = this.actRoute.snapshot.params.id;
    }
    getPreceptorDetails():void {
        const data = {
            "Entity": "PreceptorDetails",
            "PreceptorId": this.preceptorId,
        };
        this._proxyService.postData(ServiceUrl.GetPreceptorDetailsByPreceptorId,data)
        .subscribe((resp:any) => {
            this.preceptorDetails=resp.Response;
            this._proxyService.decrementRequestCount();
        },error=>this._proxyService.decrementRequestCount());
    }

    ngOnInit(): void {
        this.getPreceptorDetails();
    }
    editPreceptor() { this.router.navigate(['/preceptorform/', this.preceptorDetails.PreceptorId]);}

    deletePreceptor() {
        this.confirmationDialogService.confirm('Delete Preceptor', 'Are you sure to delete the Preceptor '+ this.preceptorDetails.PrecFirstName+' ' + this.preceptorDetails.PrecLastName + '?')
        .then((confirmed) => {
            if(confirmed) this.preceptorDelete()})
        .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
      }
    preceptorDelete(): void {
        const deletePreceptor = {
            "Entity": "PreceptorDetails",
            "PreceptorId": this.preceptorId
        }
        this._proxyService.postData(ServiceUrl.DeletePreceptorDetails,deletePreceptor)
        .subscribe(resp => {
            this.toastr.success('Successfully Deleted', '');
            this.router.navigate(['/preceptorlist']);
            this._proxyService.decrementRequestCount(); 
        },err =>{ this._proxyService.decrementRequestCount(); this.toastr.error(err, '');});
    }
}
