// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,

  ServicePartnerDataURL: "https://apim-internal-dev.academicpartnerships.com/PDMWebApi/",
  //preceptorServiceURL : 'https://preceptorqaservice.academicpartnerships.com/',
  preceptorServiceURL: 'https://preceptordevservice.academicpartnerships.com/',
  //Azure AD App Configurations
  adAauthority: 'https://login.microsoftonline.com/8720e855-7e28-49ff-a1a7-8d5e66b5659e/',
  adClientId: "ba40b849-da62-47d7-af55-1b22e243915c",
  adTenantId: "8720e855-7e28-49ff-a1a7-8d5e66b5659e",
  adPostLogoutRedirectUri: "https://preceptordev.academicpartnerships.com",
  adRedirectUrl: 'https://preceptordev.academicpartnerships.com',
  apiConfig: {
    scopes: ['api://AP.Preceptor.API-DEV/access_as_user']
  }
};

