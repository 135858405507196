import { Injectable,EventEmitter } from '@angular/core';
import { MsalService } from '../msal/msal.service';
 
@Injectable()
export class AuthenticationService {
    isLoggined = new EventEmitter();
    isLoading= new EventEmitter();
    public userName: any;
    constructor(private authService: MsalService) { }
    
    checkLoading(isload:boolean){
        setTimeout(() => {
        this.isLoading.emit(isload);
        },0);
    }
}

