<div id="wrapper" class="middle-wrapper">
  <app-header></app-header>
  <div id="middle-wrapper" class="container-fluid middle-content"  [hidden]="isLoadingPanel">
    <router-outlet></router-outlet>
  </div>
  <app-footer>
  </app-footer>
</div>

<div class="loading-spiner-holder" [hidden]="!isLoadingPanel">
    <div class="loading-spiner">
        <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
        </div>
    </div>
</div>