import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { AuthenticationService, PreceptorService, ProxyService } from '../shared/';
import { faLongArrowAltLeft ,faCalendarAlt,faCalendar} from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormBuilder,FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { NewPreceptor } from './new-preceptor';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceUrl } from '../utils/serviceurl.constants';

@Component({
  selector: 'app-preceptor-form',
  templateUrl: './preceptor-form.component.html',
  styleUrls: ['./preceptor-form.component.scss']
})
export class PreceptorFormComponent implements OnInit {
    predfinedPartnerList= ['UTA', 'NKU', 'STU', 'LASALLE', 'La Salle', 'SUNY', 'Houston', 'UH','WPUNJ', 'YSU', 'ASU'];
    BusinessContractStatusTypeNames= ['Active', 'Anticipating Separation'];
    isValidFormSubmitted:any = null;
    preceptorId: any;
    faArrowLeft=faLongArrowAltLeft;
    faCalendarAlt=faCalendarAlt;
    stateUs:any=[];
    submitted = false;
    universities:any;
    constructor(private actRoute: ActivatedRoute,
        private preceptorService: PreceptorService,
        private _auth:AuthenticationService,
        private confirmationDialogService :ConfirmationDialogService,
        private toastr: ToastrService,
        protected cdr: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private Activatedroute:ActivatedRoute,
        private router:Router,private _proxyService:ProxyService) {}

    preceptorForm = new FormGroup({
        PrecFirstName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        PrecLastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        InternalUniversityCode: new FormControl('',[Validators.required]),
        Credientials:new FormControl('',[Validators.required]),
        Speciality:new FormControl('',[]),
        Practice:new FormControl('', [Validators.required, Validators.maxLength(50)]),
        Email:new FormControl('',[Validators.required, Validators.maxLength(50), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        Address:new FormControl('',[]),
        CityId:new FormControl('',[]),
        StateCode:new FormControl('',[]),
        Zip:new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$")]),
        Phone:new FormControl('',[Validators.required,Validators.minLength(10),RxwebValidators.mask({mask:'(999)-999 9999' })]),
        AltPhone:new FormControl('',[Validators.minLength(10),RxwebValidators.mask({mask:'(999)-999 9999' })]),
        DirectMail: new FormControl('',[]),
        ContractExpiration:new FormControl('',[]),
        LicenseExpiration:new FormControl('',[]),
        LicenseInfo:new FormControl('',[]),
        CertificationDate:new FormControl('',[]),
        SchedExpiration:new FormControl('',[]),
        BoardCertified:new FormControl('',[]),
        IsAccepted:new FormControl('',[Validators.required]),
        Source:new FormControl('',[Validators.required]),
        FieldAgentName:new FormControl('',[]),
        Notes:new FormControl('',[])
    });
    preceptor = new NewPreceptor();
    preceptorFormSubmit() {
        this.isValidFormSubmitted = false;
        if (this.preceptorForm.invalid) return;
        if (this.preceptorId) {
            var data = {
                "Entity": "PreceptorDetails",
                "Parameters": this.preceptorForm.value
            };
            data.Parameters.UpdatedBy=JSON.parse(localStorage['userDetail']).UserName;
            data.Parameters.PreceptorId=this.preceptorId;
            this._proxyService.postData(ServiceUrl.UpdatePreceptorDetails,data)
                .subscribe(resp => {
                    this.newPreceptor();
                    this.toastr.success('Successfully updated', '');
                    this.isValidFormSubmitted = true;
                    this._proxyService.decrementRequestCount();
                },error=>{this.toastr.error(error, '');this._proxyService.decrementRequestCount();});
        } else {
            const data = {
                "Entity": "PreceptorDetails",
                "PreceptorList":[this.preceptorForm.value]
            };
            data.PreceptorList[0].CreatedBy=JSON.parse(localStorage['userDetail']).UserName;
            this._proxyService.postData(ServiceUrl.SavePreceptorDetails,data)
                .subscribe(resp => {
                    this.newPreceptor();
                    this.toastr.success('Successfully updated', '');
                    this.router.navigate(['/preceptorlist']);
                    this.isValidFormSubmitted = true;
                    this.preceptor = this.preceptorForm.value;
                    this.preceptorForm.reset();
                    this._proxyService.decrementRequestCount();
                },error=>{this.toastr.error(error,''); this._proxyService.decrementRequestCount();}
            );
        }
    }
    get PrecFirstName() {return this.preceptorForm.get('PrecFirstName');}
    get PrecLastName() {return this.preceptorForm.get('PrecLastName');}
    get InternalUniversityCode() {return this.preceptorForm.get('InternalUniversityCode');}  
    get Credientials() {return  this.preceptorForm.get('Credientials')};
    get Speciality() {return this.preceptorForm.get('Speciality')};
    get Practice() {return this.preceptorForm.get('Practice')};
    get Email() {return this.preceptorForm.get('Email')};
    get Address() {return this.preceptorForm.get('Address')}
    get CityId() {return this.preceptorForm.get('CityId')};
    get StateCode() {return this.preceptorForm.get('StateCode')};
    get Zip() {return this.preceptorForm.get('Zip')};
    get Phone() {return this.preceptorForm.get('Phone')};
    get AltPhone() {return this.preceptorForm.get('AltPhone')};
    get DirectMail() {return this.preceptorForm.get('DirectMail')};
    get ContractExpiration() {return this.preceptorForm.get('ContractExpiration')};
    get LicenseExpiration() {return this.preceptorForm.get('LicenseExpiration')};
    get LicenseInfo() {return this.preceptorForm.get('LicenseInfo')};
    get CertificationDate() {return this.preceptorForm.get('CertificationDate')};
    get SchedExpiration() {return this.preceptorForm.get('SchedExpiration')};
    get BoardCertified() {return this.preceptorForm.get('BoardCertified')};
    get IsAccepted() {return this.preceptorForm.get('IsAccepted')};
    get Source() {return this.preceptorForm.get('Source')};
    get FieldAgentName() {return this.preceptorForm.get('FieldAgentName')};
    get Notes() {return this.preceptorForm.get('Notes')};
    
    ngOnInit(): void {
        this.getPartners();
        this.Activatedroute.queryParams.subscribe(params=>this.preceptorId=params.PreceptorId);
        if(this.preceptorId) this.getPreceptorDetails();
        this.getStates();    
    }
    getStates():void {
        const data = {entity:'States'};
        this._proxyService.getData(ServiceUrl.GetAllStates)
        .subscribe(
            res=>{ this.stateUs= res ;this._proxyService.decrementRequestCount()},
            error=>{this._proxyService.decrementRequestCount()}
        )
    }
    getPreceptorDetails():void {
        let data = {
            "Entity": "PreceptorDetails",
            "PreceptorId": this.preceptorId,
        };
        this._proxyService.postData(ServiceUrl.GetPreceptorDetailsByPreceptorId,data)
        .subscribe(
            (resp:any) => {
                var data=resp.Response;
                this.preceptorForm.patchValue({
                PrecFirstName: data.PrecFirstName,
                PrecLastName:data.PrecLastName,
                InternalUniversityCode:data.InternalUniversityCode,
                Credientials:data.Credientials,
                Speciality:data.Speciality,
                Practice:data.Practice,
                Email:data.Email,
                Address: data.Address,
                CityId:data.CityId,
                StateCode:data.StateCode,
                Zip:data.Zip,
                Phone!: data.Phone,
                AltPhone!:data.AltPhone==0?'':data.AltPhone,
                DirectMail!:data.DirectMail,
                ContractExpiration!:data.ContractExpiration,
                LicenseExpiration!:data.LicenseExpiration,
                LicenseInfo!:data.LicenseInfo,
                CertificationDate!:data.CertificationDate,
                SchedExpiration!:data.SchedExpiration,
                BoardCertified!:data.BoardCertified,
                IsAccepted!:data.IsAccepted,
                Source!:data.Source,
                FieldAgentName!:data.FieldAgentName,
                Notes!:data.Notes
                });
                this._proxyService.decrementRequestCount();
            },error=>{this._proxyService.decrementRequestCount() });
    }

    getPartners(): void {
        this._proxyService.postData(ServiceUrl.getPartnersList,{})
        .subscribe(
            (response:any) => {this.universities = response.ApiResponse;this._proxyService.decrementRequestCount();},
            error=>{console.log('Preceptorlisterror:'+error);this._proxyService.decrementRequestCount();}
        );
    }
    newPreceptor(): void {
        this.submitted = false;
        this.preceptor = new NewPreceptor();
    }
}

