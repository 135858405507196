export class NewPreceptor {
    PrecTitle!: string;
    PrecFirstName!: string;
    PrecLastName!: string;
    InternalUniversityCode: string | undefined;
    Credientials:string|undefined;
    Speciality:string|undefined;
    Practice:string|undefined;
    Email: string | undefined;
    Address: string|undefined;
    CityId:string|undefined;
    StateCode:string|undefined;
    Zip:number|undefined;
    Phone!: number;
    AltPhone!:number;
    DirectMail!:Date;
    ContractExpiration!:Date;
    LicenseExpiration!:Date;
    LicenseInfo!:string;
    CertificationDate!:Date;
    SchedExpiration!:Date;
    BoardCertified!:string;
    IsAccepted!:string;
    Source!:string;
    FieldAgentName!:string;
    Notes!:string;
  };
  