import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from './msal';
import { MSAL_GUARD_CONFIG } from './msal/constants';
import { MsalGuardConfiguration } from './msal/msal.guard.config';


import {  AuthenticationResult } from '@azure/msal-browser';
import { AuthenticationService } from './shared/authentication.service';
import { commonService } from './shared/common.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isIframe = false;
  loggedIn = false;
  isLoadingPanel: boolean = false;
  constructor(
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private _auth: AuthenticationService,
    private _data: commonService
  ) {}

  ngOnInit(): void {

    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          this.authService.instance.setActiveAccount(result.account);
          console.log(result.account);
          this.saveUserPreferences(result.account);
        }
      },
      error: (error) => console.log(error)
    });

     this._auth.isLoading.subscribe((data) => {
        this.isLoadingPanel = data;
    });
  }

  
  private saveUserPreferences(account:any) {
    let globals = {
        UserId: account.idTokenClaims.preferred_username,
        UserName: account.name
    };
    localStorage.setItem('userDetail', JSON.stringify(globals));
  
}

}