import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
//const DEVServicePrceptorURL = 'http://localhost:50333/';
const preceptorServiceURL = 'https://preceptorqaservice.academicpartnerships.com/';
const partnerdataurl ='https://apim-internal-qat.academicpartnerships.com/PDMWebApi/';
@Injectable({
  providedIn: 'root'
})
export class PreceptorService {
  constructor(private httpClient: HttpClient,
   // private environment:environment
    ) { }
  getPartners(): Observable<any> {
    return this.httpClient.post(`${partnerdataurl}api/partnerdata/GetAllPartner`,{});
  }
  getAllPreceptors(data:any): Observable<any> {
    return this.httpClient.post(environment.preceptorServiceURL+'api/Preceptor/GetAllPreceptorDetails',data);
  }
  createPreceptor(data:any):Observable<any> {
      return this.httpClient.post(environment.preceptorServiceURL+'api/Preceptor/SavePreceptorDetails',data);
  }

  updatePreceptor(data:any):Observable<any> {
    return this.httpClient.post(environment.preceptorServiceURL+'api/Preceptor/UpdatePreceptorDetails',data);
    }
  getPreceptorDetailsByPreceptorId(data:any):Observable<any> {
      return this.httpClient.post(environment.preceptorServiceURL+'api/Preceptor/GetPreceptorDetailsByPreceptorId',data);
  }
  deletePreceptor(data:any):Observable<any> {
      return this.httpClient.post(environment.preceptorServiceURL+'api/Preceptor/DeletePreceptorDetails',data)
  }
  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "server error.");
}
//   read(id:any): Observable<any> {
//     return this.httpClient.get(`${DEVServicePrceptorURL}/${id}`);
//   }

//   update(id:any, data:any): Observable<any> {
//     return this.httpClient.put(`${DEVServicePrceptorURL}/${id}`, data);
//   }

//   delete(id:any): Observable<any> {
//     return this.httpClient.delete(`${DEVServicePrceptorURL}/${id}`);
//   }

//   deleteAll(): Observable<any> {
//     return this.httpClient.delete(DEVServicePrceptorURL);
//   }

//   searchByName(name:any): Observable<any> {
//     return this.httpClient.get(`${DEVServicePrceptorURL}?name=${name}`);
//   }
}
