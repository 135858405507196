import { environment } from '../../environments/environment';
const serviceBaseUrl = environment.preceptorServiceURL;
//const authBaseUrl = environment.authBaseUrl;
export const ServiceUrl = {
    GetPreceptorDetailsByPreceptorId: serviceBaseUrl + 'api/Preceptor/GetPreceptorDetailsByPreceptorId',
    DeletePreceptorDetails: serviceBaseUrl + 'api/Preceptor/DeletePreceptorDetails',
    UpdatePreceptorDetails: serviceBaseUrl + 'api/Preceptor/UpdatePreceptorDetails',
    getPreceptorsList: serviceBaseUrl + 'api/Preceptor/GetAllPreceptorDetails',
    insertPreseptorDetails: serviceBaseUrl + 'api/Preceptor/',
    getPartnersList: environment.ServicePartnerDataURL + 'api/partnerdata/GetAllPartner',
    SavePreceptorDetails: serviceBaseUrl + 'api/Preceptor/SavePreceptorDetails',
    GetAllStates:serviceBaseUrl+'api/Preceptor/GetAll?entity=states'
    //getPreceptorsList:serviceBaseUrl+'api/Preceptor/GetAllStates'
} 
