<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
      <!-- <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button> -->
    </div>
    <div class="modal-body">
      {{ message }}
    </div>
    <div class="modal-footer">
        <button  class="btn btn-primary cust-btn" (click)="accept()">{{ btnOkText }}</button>
        <button class="btn btn-deafult cust-btn" (click)="decline()">{{ btnCancelText }}</button>
     
    </div>