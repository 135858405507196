<div class="preceptor_list">
    <div class="addnewpreceptor">
        <a href="/#/" class="home-back back_arrow"> <fa-icon [icon]="faArrowLeft" class="faiconstyle" aria-hidden="true"  style="vertical-align:text-bottom;"></fa-icon>  Back</a>
    </div>
    <div class="content-wrapper form-padding" *ngIf="preceptorDetails">
        <div class="row">
            <div class="col-12 ">
                <div class="align-right">
                    <a [routerLink]="['/preceptorform/']" [queryParams]="{PreceptorId: preceptorDetails.PreceptorId}" ><span class="edit-icon"></span></a>
                    <span (click)="deletePreceptor()" class="delete-icon"></span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Name</div>
                    <label class="control-label detail-data" >{{preceptorDetails.PrecFirstName}} {{preceptorDetails.PrecLastName}}</label>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Partner</div>
                    <label class="control-label detail-data">{{preceptorDetails.InternalUniversityCode}}</label>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Credentials</div>
                    <label class="control-label detail-data" >{{preceptorDetails.Credientials}}</label>
                </div>
            </div>
            
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4" *ngIf="preceptorDetails.Credientials=='Nurse Practitioner (PNP, FNP, ANP, AGNP)'">
                <div class="form-group" >
                    <div class="detail-label">Specialty Area</div>
                    <label class="control-label detail-data" > {{preceptorDetails.Speciality}}</label>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Practice Name</div>
                    <label class="control-label detail-data" >{{preceptorDetails.Practice}}</label>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Email</div>
                    <label class="control-label detail-data" >{{preceptorDetails.Email}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Street Address</div>
                    <label class="control-label detail-data" >{{preceptorDetails.Address}}</label>
                </div>
                
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">City</div>
                    <label class="control-label detail-data" >{{preceptorDetails.CityId}}</label>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">State</div>
                    <label class="control-label detail-data" >{{preceptorDetails.StateCode}}</label>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Zip Code</div>
                    <label class="control-label detail-data" >{{preceptorDetails.Zip}}</label>

                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Phone</div>
                    <label class="control-label detail-data" >{{preceptorDetails.Phone | tel}}</label>

                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Alt. Phone</div>
                    <label class="control-label detail-data" >{{preceptorDetails.AltPhone | tel}}</label>

                  </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Direct Mail</div>
                    <label class="control-label detail-data" >{{preceptorDetails.DirectMail | date:'MM-dd-yyyy'}}</label>

                     </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Contract Expiration</div>
                    <label class="control-label detail-data" >{{preceptorDetails.ContractExpiration | date:'MM-dd-yyyy'}}</label>

                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">License Expiration</div>
                    <label class="control-label detail-data" >{{preceptorDetails.LicenseExpiration | date:'MM-dd-yyyy'}}</label>

                     </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">License Info</div>
                    <label class="control-label detail-data" >{{preceptorDetails.LicenseInfo}}</label>

                       </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Certification Date</div>
                    <label class="control-label detail-data">{{preceptorDetails.CertificationDate | date:'MM-dd-yyyy'}}</label>

                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Sched. A Expiration</div>
                    <label class="control-label detail-data" >{{preceptorDetails.SchedExpiration | date:'MM-dd-yyyy'}}</label>

                   </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Board Certified</div>
                    <label class="control-label detail-data" >{{preceptorDetails.BoardCertified}}</label>

                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Become a Preceptor?</div>
                    <label class="control-label detail-data" >{{preceptorDetails.IsAccepted}}</label>

                   </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4" >
                <div class="form-group">
                    <div class="detail-label">Source</div>
                    <label class="control-label detail-data" >{{preceptorDetails.Source}}</label>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-4" *ngIf="preceptorDetails.Source =='Field Sales'">
                <div class="form-group">
                    <div class="detail-label">Name</div>
                    <label class="control-label detail-data">{{preceptorDetails.FieldAgentName}}</label>

                   
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
                <div class="form-group">
                    <div class="detail-label">Notes</div>
                    <label class="control-label detail-data">
                        <pre [innerHtml]="preceptorDetails.Notes">
</pre>
                    </label></div></div>
                </div>
            </div> 
</div>
