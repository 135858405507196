import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterByPartnerCodeAndStatus',
  pure:false
})
export class FilterByPartnerCodeAndStatusPipe implements PipeTransform {

    transform(universities: any[], predfinedPartnerList:any[],BusinessContractStatusTypeNames:any[]): any {
        try {
            return universities.filter(item => (predfinedPartnerList.findIndex(x => x === item.InternalUniversityCode)!== -1) && (BusinessContractStatusTypeNames.findIndex(x=>x===item.BusinessContractStatusTypeName) !=-1) )
        } catch (error) {
            return universities;
        }
    }

}
