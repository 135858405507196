<div class="preceptor_list">
    <div class="addnewpreceptor">
        <a routerLink="/preceptorform" class="addprec">New Preceptor</a>
    </div>

    <div class="middle" id="mainContent">
        <div class="content">
            <div class="midLeft">
                <div class="program-filter">
                    <div class="filter-group">
                        <div class="linkbox header">
                            <div class="linkbox-left">
                                <span class="filter-group-title">Filter by </span>
                            </div>
                            <div class="linkbox-right filter-reset" [hidden]="!repeatArray.length">
                                <span class="clear-filter" (click)="ClearFilters(true)">Clear All X</span>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngFor="let y of check;index as i">
                        <div class="filter-group">
                            <div class="linkbox show-link" [ngClass]="{open: y.display}" (click)="onLinkClick(i)">
                                <div class="linkbox-left">
                                    <span class="filter-group-title" [hidden]="y.checked"> {{y.section}}</span>
                                    <span class="filter-group-title filter-group-title-open"
                                        [hidden]="!y.checked">{{y.section}}</span>
                                </div>
                                <div class="linkbox-right show-link-cta">
                                    <span [hidden]="y.checked"><img src="/Images/linkbox-arrow-closed.png"
                                            alt="" /></span>
                                    <span [hidden]="!y.checked"><img src="/Images/linkbox-arrow-open.png"
                                            alt="" /></span>
                                </div>
                            </div>
                        </div>
                        <div id="id_{{y.section}}" class="checkbox-filter-group animate-show-hide" [hidden]="!y.checked"
                            ng-clock="">
                            <fieldset>
                                <ng-container *ngFor="let x of y.checkBoxes| orderBy:'name'">
                                    <div *ngIf="filterByPartnerCodeAndStatus(x)">
                                        <input [(ngModel)]="x.mode" id="{{x.id}}_{{x.name}}"
                                            (click)="onCheckBoxClick(x)" class="standard-checkbox checkbox-input"
                                            name="{{x.value}}" type="checkbox" value="{{x.value}}" />
                                        <label for="{{x.id}}_{{x.name}}" class="checkbox_name">{{x.name}}</label>
                                    </div>
                                </ng-container>
                            </fieldset>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="midRight ">
                <div class="active-filters" ng-cloak="" *ngIf="repeatArray.length">
                    <ul>
                        <li id="activeFilter" (click)="removeBox(programs)" *ngFor="let programs of repeatArray"><span
                                id="close" class="pd-rt-10">{{programs.name}}</span> X</li>
                    </ul>
                </div>
                <div class="content-wrapper">
                    <div class="row searchBar">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <input type="search" [(ngModel)]="searchval" (ngModelChange)="reRender()"
                                name="preceptorSearch" class="form-control preceptorSearch"
                                placeholder="Search by Name, Phone Number, Email ,Credentials,Practice and Zip ">
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table [hidden]="!filteredpreceptorsList?.length" [dtOptions]="dtOptions" datatable
                            class="table table-striped table-hover" [dtTrigger]="dtTrigger">
                            <thead [hidden]="!filteredpreceptorsList?.length">
                                <tr class="dtheader">
                                    <th class="dtheader">Date Created</th>
                                    <th>Partner</th>
                                    <th>Preceptor Name</th>
                                    <th>Email</th>
                                    <th>Credentials</th>
                                    <th>License Number</th>
                                    <th>State</th>
                                    <th>Zip Code</th>
                                    <th>Phone Number</th>
                                </tr>
                            </thead>
                            <tbody [hidden]="!filteredpreceptorsList?.length">
                                <ng-container *ngFor="let preceptor of filteredpreceptorsList">
                                    <tr (click)="precDetail(preceptor.PreceptorId)">
                                        <td><span class="datehide">{{preceptor.CreatedDate | date: 'yyyyMMdd'}}</span>{{preceptor.CreatedDate | date: 'MM/dd/yyyy'}}</td>
                                        <td>{{preceptor.InternalUniversityCode}}</td>
                                        <td><span>{{preceptor.PrecFirstName}} {{preceptor.PrecLastName}}</span> </td>
                                        <td>{{preceptor.Email}}</td>
                                        <td>{{preceptor.Credientials}}</td>
                                        <td>{{preceptor.LicenseInfo}}</td>
                                        <td>{{preceptor.StateCode}}</td>
                                        <td>{{preceptor.Zip}}</td>
                                        <td class="phonenum">{{preceptor.Phone|tel }}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>