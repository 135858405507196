import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreceptorDetailsComponent } from './preceptor-details/preceptor-details.component';
import { PreceptorsListComponent } from './preceptors-list/preceptors-list.component'
import { MsalGuard } from './msal';
import { PreceptorFormComponent } from './preceptor-form/preceptor-form.component';
import { HeaderComponent } from './header/header.component';
const routes: Routes = [
  {
    path: 'preceptorlist',
    component: PreceptorsListComponent
  },
  {
    // Needed for hash routing
    path: 'code',
    component: HeaderComponent
  },
  {
    path: '',
    component: HeaderComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
      path:'preceptordetails/:id',
      component:PreceptorDetailsComponent
  },
  {
      path:'preceptorform',
      component: PreceptorFormComponent
  }
];
const isIframe = window !== window.parent && !window.opener;
@NgModule({

  imports: [RouterModule.forRoot(routes, {

      useHash: true,

      // Don't perform initial navigation in iframes

      initialNavigation: !isIframe ? 'enabled' : 'disabled'

  })],

  exports: [RouterModule]

})

export class AppRoutingModule { }