<div class="preceptor_list">
    <div class="addnewpreceptor ">
        <a href="/#/" class="home-back back_arrow"> <fa-icon [icon]="faArrowLeft" class="faiconstyle" aria-hidden="true"  style="vertical-align:text-bottom;"></fa-icon>  Back</a>
    </div>
    <div class="content-wrapper form-padding">
        <form role="form" name="preceptorFormUi" [formGroup]="preceptorForm" (ngSubmit)="preceptorFormSubmit()">
            <div class="row">
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group required">
                        <label class="control-label">First Name</label>
                        <input tabindex="1" formControlName="PrecFirstName" class="form-control"  placeholder="First Name" maxlength="50">
                        <div *ngIf="PrecFirstName?.errors && isValidFormSubmitted != null && !isValidFormSubmitted" [ngClass] = "'error'"> 
                            <span class="form-error has-error" *ngIf="PrecFirstName?.errors?.required">First Name is required</span>    
                            <span class="form-error has-error" *ngIf="PrecFirstName?.errors?.maxlength">First Name can be max 50 characters long.</span>	
                       </div>	
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group required">
                        <label class="control-label">Last Name</label>
                        <input tabindex="2" formControlName="PrecLastName" class="form-control"  placeholder="Last Name" maxlength="50">
                        <div *ngIf="PrecLastName?.errors && isValidFormSubmitted != null && !isValidFormSubmitted" [ngClass] = "'error'">
                            <span class="form-error has-error" *ngIf="PrecLastName?.errors?.required">Last Name is required</span>    
                            <span class="form-error has-error" *ngIf="PrecLastName?.errors?.maxlength">First Name can be max 50 characters long.</span>	 
                       </div>	
                        
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group required">
                        <label class="control-label">Partner</label> 
                        <select tabindex="3" class="form-control"  formControlName="InternalUniversityCode" >
                            <option value="" disabled>Select Partner</option>
                            <option *ngFor="let item of universities| filterByPartnerCodeAndStatus:predfinedPartnerList:BusinessContractStatusTypeNames | orderBy:'UniversityFullName'" [ngValue]="item.InternalUniversityCode"> {{item.UniversityFullName}}</option>
                        </select>
                        <div *ngIf="InternalUniversityCode?.errors && isValidFormSubmitted != null && !isValidFormSubmitted" [ngClass] = "'error'"> 
                            <span class="form-error has-error" *ngIf="InternalUniversityCode?.errors?.required">Partner is required</span>    
                       </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group required">
                        <label class="control-label">Credentials</label>
                        <select tabindex="4" class="form-control"  formControlName="Credientials">
                            <option value="">Select Credentials</option>
                            <option value="Doctor of Medicine (MD)">Doctor of Medicine (MD)</option>
                            <option value="Doctor of Osteopathy (DO)">Doctor of Osteopathy (DO)</option>
                            <option value="Nurse Practitioner (PNP, FNP, ANP, AGNP)">Nurse Practitioner (PNP, FNP, ANP, AGNP)</option>
                            <option value="Physician Assistant (PA)">Physician Assistant (PA)</option>
                            <option value="Other">Other</option>
                        </select>
                        <div *ngIf="Credientials?.errors && isValidFormSubmitted != null && !isValidFormSubmitted" [ngClass] = "'error'"> 
                            <span class="form-error has-error" *ngIf="Credientials?.errors?.required">Credentials is required</span>    
                       </div>
                    </div>
                </div>
                 <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group" *ngIf="Credientials?.value=='Nurse Practitioner (PNP, FNP, ANP, AGNP)'">
                        <label class="control-label">Specialty Area</label>
                        <select tabindex="5" class="form-control" formControlName="Speciality" >
                            <option value="">Select Speciality</option>
                            <option value="Family NP">Family NP</option>
                            <option value="Adult Gero PC NP">Adult Gero PC NP</option>
                            <option value="Adult Gero AC NP">Adult Gero AC NP</option>
                            <option value="Pediatric PC NP">Pediatric PC NP</option>
                            <option value="Pediatric AC NP">Pediatric AC NP</option>
                            <option value="Psych NP">Psych NP</option>
                            <option value="Women's Health NP">Women's Health NP</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group required">
                        <label class="control-label">Practice Name</label>
                        <input tabindex="6" class="form-control" formControlName="Practice" maxlength="50"  placeholder="Practice Name"  />
                               <div *ngIf="Practice?.errors && isValidFormSubmitted != null && !isValidFormSubmitted" [ngClass] = "'error'"> 
                                <span class="form-error has-error" *ngIf="Practice?.errors?.required">Practice Name is required</span>    
                                <span class="form-error has-error" *ngIf="PrecLastName?.errors?.maxlength">Practice Name can be max 50 characters long.</span>	 
                           </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group required">
                        <label class="control-label">Email</label>
                        <input  type="email" class="form-control" formControlName="Email" tabindex="7"  placeholder="Email" />
                        <div *ngIf="Email?.errors && isValidFormSubmitted != null && !isValidFormSubmitted" [ngClass] = "'error'"> 
                            <span class="form-error has-error" *ngIf="Email?.errors?.required">Email is required</span>   
                            <span class="form-error has-error" *ngIf="Email?.errors?.pattern">Enter valid email address</span> 
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label class="control-label">Street Address</label>
                        <input formControlName="Address" class="form-control" tabindex="8" placeholder="Street Address" maxlength="250" name="businessAddress" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label class="control-label">City</label>
                        <input formControlName="CityId" class="form-control" maxlength="50" tabindex="9"  placeholder="City" />
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label class="control-label">State</label>
                        <select formControlName="StateCode" tabindex="10" class="form-control" placeholder="Select State">
                            <option value="" >Select State</option>
                            <option *ngFor="let state of stateUs" value="{{state.abbreviation}}">{{state.NAME}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group required">
                        <label class="control-label">Zip Code</label>
                        <input formControlName="Zip" class="form-control" tabindex="11"  maxlength="5"  placeholder="Zip Code" />
                        <div *ngIf="Zip?.errors && isValidFormSubmitted != null && !isValidFormSubmitted" [ngClass] = "'error'">
                            <span class="form-error has-error" *ngIf="Zip?.errors?.required">Zip Code is required</span>    
                            <span class="form-error has-error" *ngIf="Zip?.errors?.pattern">Enter valid zip code</span>	 
                        </div>
                    </div>
                </div>
            </div>
           <div class="row">
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group required">
                        <label class="control-label">Phone Number</label>
                        <input formControlName="Phone" class="form-control" tabindex="12" phone-input  maxlength="50" placeholder="Phone Number" />
                        <div *ngIf="Phone?.errors && isValidFormSubmitted != null && !isValidFormSubmitted" [ngClass] = "'error'">
                            <span class="form-error has-error" *ngIf="Phone?.errors?.required">Enter valid phone number</span>    
                            <span class="form-error has-error" *ngIf="Phone?.errors?.minlength">Enter valid phone number</span>	 
                        </div>
                    </div>
                </div>
                 <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label  class="control-label">Alt. Phone</label>
                        <input formControlName="AltPhone" class="form-control" tabindex="13" placeholder="Alt. Phone"  />
                        <div *ngIf="AltPhone?.errors && isValidFormSubmitted != null && !isValidFormSubmitted" [ngClass] = "'error'">
                            <span class="form-error has-error" *ngIf="AltPhone?.errors?.minLength">Enter valid phone number</span>	 
                        </div>
                    </div>
                </div>
               <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label class="control-label">Direct Mail</label>
                        <div class="input-group">
                            <app-datepicker-input class="form-control" placeholder="Direct Mail"
                             tabindex="14" formControlName='DirectMail'></app-datepicker-input>
                        </div>
                    </div>
                </div> 
            </div>
             <div class="row">

                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label class="control-label">Contract Expiration</label>
                        <div class="input-group">
                            <app-datepicker-input class="form-control" placeholder="Contract Expiration"
                             tabindex="15" formControlName='ContractExpiration'></app-datepicker-input>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label class="control-label">License Expiration</label>
                        <div class="input-group">
                            <app-datepicker-input class="form-control" placeholder="License Expiration" tabindex="16" formControlName='LicenseExpiration'></app-datepicker-input>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label class="control-label">License Info</label>
                        <input class="form-control" tabindex="17" maxlength="50" formControlName="LicenseInfo" name="licenseInfo" placeholder="License Info" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label class="control-label">Certification Date</label>
                        <div class="input-group">
                            <app-datepicker-input class="form-control" placeholder="Certification Date" tabindex="18" formControlName='CertificationDate'></app-datepicker-input>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label class="control-label">Sched. A Expiration</label>
                        <div class="input-group">
                            <app-datepicker-input class="form-control" placeholder="Sched. A Expiration" tabindex="19" formControlName='SchedExpiration'></app-datepicker-input>
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group">
                        <label class="control-label">Board Certified</label>
                        <select class="form-control" tabindex="20" name="boardcertified" formControlName="BoardCertified">
                            <option value="">Please Select</option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group required">
                        <label class="control-label">Become a Preceptor?</label>
                        <select tabindex="21" class="form-control" formControlName="IsAccepted">
                            <option value="">Please Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        <div *ngIf="IsAccepted?.errors && isValidFormSubmitted != null && !isValidFormSubmitted" [ngClass] = "'error'">
                            <span class="form-error has-error" *ngIf="IsAccepted?.errors?.required">Become a Preceptor is required</span>    
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4">
                    <div class="form-group required">
                        <label class="control-label">Source</label>
                        <select tabindex="22"  formControlName="Source" class="form-control">
                            <option value="">Select Source</option>
                            <option value="University Partner">University Partner</option>
                            <option value="Online">Online</option>
                            <option value="Fax">Fax</option>
                            <option value="Email">Email</option>
                            <option value="Inbound Call">Inbound Call</option>
                            <option value="Field Sales">Field Sales</option>
                            <option value="Direct Mail">Direct Mail</option>
                            <option value="Referral">Referral</option>
                            <option value="Conference">Conference</option>
                            
                            @*<option value="Other">Other</option>*@
                        </select>
                        <div *ngIf="Source?.errors && isValidFormSubmitted != null && !isValidFormSubmitted" [ngClass] = "'error'">
                            <span class="form-error has-error" *ngIf="Source?.errors?.required">Source is required</span>    
                        </div>
                    </div>
                </div>
                <div class="col-4 col-md-4 col-sm-4" *ngIf="Source?.value=='Field Sales'">
                    <div class="form-group ">
                        <label class="control-label">Name</label>
                        <input class="form-control" maxlength="50" tabindex="23" formControlName="FieldAgentName"  placeholder="Field Agent Name"  />
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                        <label class="control-label">Notes</label>
                        <textarea tabindex="24" class="form-control" formControlName="Notes" placeholder="Notes"></textarea>
                    </div>
                </div>
            </div> 
            <div class="row">
                <div class="col col-lg-4 offset-8 col-offset-8">
                    <div class="form-group">
                        <button type="submit" tabindex="25" validation-submit="preceptorFormUi" class="btn btn-primary btn-block btn-lg">Submit</button>
                    </div>
                </div>
            </div>
    </form>
    </div>

</div>

